module.exports = {
  debug: false,
  i18n: {    
    defaultLocale: 'kr',  
    locales: ['kr','b2']
  },
  localeDetection: false,
  ns: ['common'],
  serializeConfig: false,
  localePath:
    typeof window === 'undefined'
      ? require('path').resolve('./public/locales')
      : '/locales',
  reloadOnPrerender: process.env.NODE_ENV === 'development',

  parseMissingKeyHandler: (lng, ns, key, fallbackValue) => {
    return "대기중";
}
}