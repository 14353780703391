import Image from "next/image";
import styles from "./styles.module.scss";
import { cloudflareLoader } from "@/core-nextv3/util/util";

function ErrorMessage({ message }: any) 
{
    return (
        <div className={styles.errorMessage}>
            <Image
                width={10}
                height={10}
                src="/assets/icons/AiOutlineExclamationCircle.svg"
                loader={cloudflareLoader}
                alt=""
            />
            {message} 
        </div>
    );
}

export default ErrorMessage;
