import { Timestamp } from "firebase/firestore";
import { getTimezoneOffset, toZonedTime } from "date-fns-tz";
import { fromUnixTime, isValid, format, sub, add, parse, getDate, differenceInHours, 
    differenceInDays, differenceInMonths, endOfDay, startOfDay, startOfMonth, endOfMonth, 
    isSameSecond, isBefore, isAfter, getDaysInMonth, setDate, setHours, setMinutes, setSeconds, differenceInYears, 
    isSameDay, getDay, 
    setDay } from "date-fns";
export class TDate
{
    public date : any;

    constructor(args?:any)
    {
        // DATA SALVAS NAO PRECISA FAZER OFFSET DE HORA
        if (args && args.value)
        {
            if (typeof args.value == "string")
            {			
                if (args.mask)
                {
                    console.error("xxxxx", args);

                    this.date = parse(args.value, args.mask, Timestamp.now().toDate());
                }
                else
                {
                    this.date = new Date(args.value);
                }
            }
            else if (args.value instanceof Date)
            {			
                this.date = args.value;
            }
            else if (args.value instanceof TDate)
            {			
                this.date = args.value.date;
            }
            else if (args.value instanceof Timestamp)
            {			
                this.date = args.value.toDate();
            }
            else if (args.value._seconds)
            {
                this.date = fromUnixTime(args.value._seconds);
            }
            else if (args.value.seconds)
            {
                this.date = fromUnixTime(args.value.seconds);
            }
        }
        else
        {
            this.date = Timestamp.now().toDate();
        }
    }

    format(mask:string = "yyyy-MM-dd\'T\'HH:mm:ssxxx", timezone?:any)
    {
        if (this.isValid())
        {			
            if (timezone)
            {
                this.date = toZonedTime(this.date, timezone.label);
            }

            return format(this.date, mask);
        }

        return "";
    }

    isValid()
    {
        return isValid(this.date);
    }

    add(value:any, format:any)
    {
        const option : any = {};
        option[format]     = value;

        this.date = add(this.date, option);

        return this;
    }

    subtract(value:any, format:any)
    {
        const option : any = {};
        option[format]     = value;

        this.date = sub(this.date, option);

        return this;
    }

    diff(value:any, format:any)
    {
        if (value instanceof TDate)
        {
            value = value.date;
        }

        if (value instanceof TDate)
        {
            value = value.date;
        }

        if (format == "hours")
        {
            return differenceInHours(this.date, value)
        }
        else if (format == "months")
        {
            return differenceInMonths(this.date, value)
        }
        else if (format == "days")
        {
            return differenceInDays(this.date, value)
        }
        else if (format == "years")
        {
            return differenceInYears(this.date, value)
        }
        else
        {
            console.error("diff error", format)
        }	

        return 0;
    }

    toDate()
    {
        return this.date;
    }

    timestamp()
    {
        return Timestamp.fromDate(this.toDate());
    }

    month()
    {
        return this.date.month();
    }

    unix()
    {
        return this.date.getTime() / 1000;
    }

    startOfDay()
    {
        return new TDate({ value : startOfDay(this.date) });
    }

    endOfDay()
    {
        return new TDate({ value : endOfDay(this.date) });
    }

    startOfMonth()
    {
        return new TDate({ value : startOfMonth(this.date) });
    }

    endOfMonth()
    {
        return new TDate({ value : endOfMonth(this.date) });
    }

    isBefore(value:TDate)
    {
        return isBefore(this.date, value.date);
    }

    isAfter(value:TDate)
    {
        return isAfter(this.date, value.date);
    }

    isSameOrBefore(value:TDate)
    {
        return isSameSecond(this.date, value.date) || isBefore(this.date, value.date);
    }

    isSameOrAfter(value:TDate)
    {
        return isSameSecond(this.date, value.date) || isAfter(this.date, value.date);
    }

    daysInMonth()
    {
        return getDaysInMonth(this.date);
    }

    setDate(dayNumber:number)
    {
        setDate(this.date, dayNumber);
    }

    setHours(value:number)
    {
        this.date = setHours(this.date, value)
    }
    setUTCHours(value:number)
    {
        this.date.setUTCHours(value)
    }
    setUTCMinutes(value:number)
    {
        this.date.setUTCMinutes(value)
    }

    setMinutes(value:number)
    {
        this.date = setMinutes(this.date, value)
    }

    setSeconds(value:number)
    {
        this.date = setSeconds(this.date, value)
    }
    setUTCSeconds(value:number)
    {
        this.date.setUTCSeconds(value)
    }

    setUTCMilliseconds(value:number)
    {
        this.date.setUTCMilliseconds(value)
    }

    getDay()
    {
        return getDay(this.date);
    }

    setDay(day:number)
    {
        this.date = setDay(this.date, day);
    }

    getDate()
    {
        return getDate(this.date);
    }
    toUTCTime(timezone: any)
    {
        // console.log(getTimezoneOffset(timezone, this.date), this.date, timezone)
        return new TDate({ value : new Date(this.date.getTime() + getTimezoneOffset(timezone, this.date)) })
    }
    startOfTimezoneDay(timezone: any)
    {
        const hours: any = this.format("HH", "Etc/UTC")
        let offsetHours  = getTimezoneOffset(timezone.label, this.date)
        offsetHours      = offsetHours / 60 / 60 / 1000

        // console.log("aaaaaaaaa", offsetHours, hours, this.date)

        //rever depois
        // if (hours + offsetHours > 23)
        // {
        //     this.add(1, "days")
        // }

        this.setUTCHours(0)
        this.setUTCMinutes(0)
        this.setUTCSeconds(0)
        this.setUTCMilliseconds(0)
        return this.toUTCTime(timezone.label);
    }
    isSameDay(value:TDate)
    {
        return isSameDay(this.date, value.date)
    }
}