import { cloudflareLoader } from "@/core-nextv3/util/util";
import styles from "./styles.module.scss";
import Image from "next/image";

function ConfirmMessage({ message }: any) 
{
    return (
        <div className={styles.confirmMessage}>
            <Image
                width={20}
                height={20}
                src="/assets/icons/AiOutlineExclamationCircle.svg"
                loader={cloudflareLoader}
                alt=""
            />
            {message}
        </div>
    );
}

export default ConfirmMessage;
